import { Document, Download } from '@dfds-ui/icons/system'
import { theme } from '@dfds-ui/theme'
import { css } from '@emotion/react'
import { graphql } from 'gatsby'

const AssetLink = (props) => {
  const { asset, linkText, presentation, ...rest } = props

  return (
    <a
      href={asset.url}
      target={'_blank'}
      download={asset.url.split('/').pop()}
      {...rest}
      css={css`
        font-weight: 700;
        display: flex;
        align-items: center;
      `}
    >
      {linkText}
      {presentation === 'Document Link' && (
        <Document
          width={'24px'}
          height={'20px'}
          color={theme.colors.text.secondary.primary}
          css={css`
            margin-left: 10px;
          `}
        />
      )}
      {presentation === 'Download Link' && (
        <Download
          width={'24px'}
          height={'20px'}
          color={theme.colors.text.secondary.primary}
          css={css`
            margin-left: 10px;
          `}
        />
      )}
    </a>
  )
}

export const AssetFragment = graphql`
  fragment Asset on contentful_Asset {
    __typename
    sys {
      id
    }
    title
    description
    contentType
    url
    width
    height
    size
  }

  fragment AssetLink on contentful_AssetLink {
    __typename
    sys {
      id
    }
    asset {
      ...Asset
    }
    linkText
    presentation
  }
`

export default AssetLink
